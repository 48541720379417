.HorizontalBars {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 50vh;
  width: 100%;
  /* the svg path etc inside are being overflown and hidden */
  /* how do we fix? */
  svg {
    margin: auto !important;
    width: 100% !important;
    height: 100% !important;
  }
  
}
