.App {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 100vh;
}

.ComponentFields {
  max-width: 1500px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  padding: auto;
}
